.page{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 30px;
}
.error{
  font-size: 6em;
}
.errorText{
  font-size: 1em;
  opacity: .7;
}
.goHomeButton{
  color: #fff;
  border-radius: 4px;
  padding: 12px 24px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #206bc4;
}